import classes from './style.module.scss';
import classNames from "classnames";
import {useState} from 'react';
import {C_Slider} from './c_Slider';
import {C_SliderElement} from './c_SliderElement/index'
import {sliderData} from '@/src/data/mocks';
import Image from 'next/image';
import "swiper/css/effect-fade";
import React, {useEffect} from "react";
import SwiperCore, {Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

interface StandartsData {
    title: string,
    section_standarts: {
        title: string,
        description: string,
        image?: any
    }[],
    btn: {
        text: string
    }[]
}

export const S_StoneStandarts = ({className, data}: { className?: any, data: StandartsData }): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    const [isIndexActiveSlide, setIsIndexActiveSlide] = useState<number>(1)
    const [swiper, setSwiper]:any = React.useState<any>(null);
    const [change, setChange]: any = React.useState<boolean>(false);
    const [isSwipe, setIsSwipe]: any = useState<boolean>(false);

    let allCards: any[] = [];

    const {title, section_standarts, btn} = data

    section_standarts.forEach((item, i) => {
        allCards.push(<C_SliderElement key={i} count={i} description={item.description}
                                       isIndexActiveSlide={isIndexActiveSlide} image={item.image} title={item.title}
                                       setIsIndexActiveSlide={setIsIndexActiveSlide} setIsSwipe={setIsSwipe} pagination={btn}/>)
    })

    useEffect(() => {
        if (swiper && !swiper.destroyed) {
            swiper === undefined ? null : swiper.slideTo(isIndexActiveSlide - 1);
            setChange(false)
        }
    }, [change, swiper])

    return (
        <div className={classes.wrapRoot}>
            <div className={cls}>
                <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
                <div className={classes.color_line}></div>
                <Swiper
                    spaceBetween={30}
                    allowTouchMove={false}
                    effect={"fade"}
                    onInit={(swiper) => setSwiper(swiper)}
                    className={classes.swiper}
                >
                    {section_standarts.map((item: any, i: any) => {
                        return (
                            <SwiperSlide className={classNames(classes.swiperSlide)} key={i}>
                                <Image src={item.image.data?.attributes?.url} width={908} height={641} alt="Фото стандарта">
                                </Image>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <C_Slider setIsSwipe={setIsSwipe} isSwipe={isSwipe} setChange={setChange} className={classes.slider} items={allCards} isIndexActiveSlide={isIndexActiveSlide} setIsIndexActiveSlide={setIsIndexActiveSlide}/>
            </div>
        </div>
    )
}