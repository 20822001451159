import classes from './style.module.scss';
import classNames from "classnames";
import {C_Slider_plans} from "./c_Slider_plans";
import {C_Situation_Plan} from "./c_Situation_Plan";
import {C_Pagination} from "./c_Pagination";
import {C_Floors} from "./c_Floors";
import {useContext, useState} from "react";
import type { Swiper } from 'swiper';
import { C_MainButton } from '@/src/components/c_MainButton';
import {Context} from "@/src/library";
import { Plan } from '..';

interface TowerPlan {
    className?: any,
    selectedSlide: number,
    setSelectedSlide: any,
    setIsSitPlanShowed?: any
    data: Plan
}

export const C_Tower_plans = ({className, selectedSlide, setSelectedSlide, setIsSitPlanShowed, data} : TowerPlan) => {
    const cls = classNames(classes.root, {[className]: className });
    const [swiperInstance, setSwiperInstance] = useState<Swiper | null | any>(null);
    const slideTo = (index: number) => {
        if (swiperInstance !== null) {
            swiperInstance.slideTo(index - 1, 0);
        }
    };

    const {menu, popup} = useContext(Context);
    const [popupState, setPopupState] = popup;
    const {name, square, implement, price, price_grow, btn_text, floors, situationPlan} = data

    return (
        <div className={cls}>
            <div className={classes.title} dangerouslySetInnerHTML={{__html: name}}></div>
            <div className={classes.blockType}>Планировка этажа</div>
            <ul className={classes.commonSquare}>
                {
                    square.map((item: any, i: number) => {
                        return (
                            <div key={i + 2} className={classes.queue} dangerouslySetInnerHTML={{__html: item.text}}></div>
                        )
                    })
                }
            </ul>
            <C_Slider_plans
                className={classNames(classes.C_Slider_plans,{[classes.C_Slider_plans_riteil]: name === 'Ритейл'})}
                plans={data}
                data={data}
                setSelectedSlide={setSelectedSlide}
                setSwiperInstance={setSwiperInstance}
            />
            <C_Situation_Plan
                setIsSitPlanShowed={setIsSitPlanShowed}
                className={classes.C_Situation_Plan}
                image={situationPlan}
            />
            <C_Pagination
                className={classes.C_Pagination}
                plans={data}
                selectedSlide={selectedSlide}
                slideTo={slideTo}
            />
            <C_Floors
                className={classes.C_Floors}
                selectedSlide={selectedSlide}
                plans={floors}
            />
            <div className={classes.wrapSquareInfo}>
                <div className={classes.squareInfo}>
                    <div className={classes.realise}>Форма реализации</div>
                    <div className={classes.saleType}>Продажа</div>
                </div>
                <ul className={classes.squareInfoList}>
                    {implement.map((el: any, i: number) => {
                        return (
                            <li key={i + 4} className={classes.squareInfoItem} dangerouslySetInnerHTML={{ __html: el.text}}/>
                        )
                    })}
                </ul>
            </div>
            <div className={classes.wrapExtraInfo}>
                <div className={classes.extraInfo}>Информация <br className={classes.onlyDesktop}/>о планировке</div>
                <div className={classes.extraInfoText}>Мокрые точки</div>
            </div>
            <div className={classes.wrapPriceInfo}>
                <div className={classes.priceInfo}>Продажа офисов <br className={classes.onlyDesktop}/>и видовых этажей</div>
                <div className={classes.priceInfoText} dangerouslySetInnerHTML={{__html: price}}></div>
            </div>
            <div className={classes.wrapPriceGrow}>
                <div className={classes.priceGrow}>Рост цены</div>
                <div className={classes.priceGrowText} dangerouslySetInnerHTML={{__html: `${price_grow}`}}></div>
            </div>
             <C_MainButton text={btn_text} className={classes.btn_cost} onClick={() => setPopupState((o: any) => !o)} />
        </div>
    );
}
