import InputMask from 'react-input-mask';
import classes from './style.module.scss';
import classNames from "classnames";
import {useContext, useId, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import Link from "next/link";
import {Context} from '@/src/library';
import { C_MainButton } from '@/src/components/c_MainButton';

type FormValues = {
    name: string;
    phone: string;
    email: string;
    policyAgree: boolean;
};

interface FormData {
    title: string,
    description: string,
    policy_link: string,
    modal_title: string,
    input: {
        name_placeholder: string,
        number_placeholder: string,
        email_placeholder: string
    },
    success: {
        title: string,
        description: string
    },
    error: {
        required: string,
        email: string,
        name: string
    },
    btn: {
        text: string
    }[]
}
declare var Comagic:any, ga:any, ym:any;
export const S_Form = ({ data, modal = false
                       }: { data: FormData, modal?: boolean }): JSX.Element => {

    const [isCheckValid, setIsCheckValid] = useState(false);
    const {lead} = useContext(Context);
    const [leadState, setLeadState] = lead;
    const validName = /^((([а-яА-ЯЁё-]){1,30})(\s{1,3}|)){1,5}$|^((([a-zA-Z-'`]){1,30})(\s{1,3}|)){1,5}$/u;

    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormValues>();

    const {btn, input, error, title, description, policy_link, modal_title} = data

    const onSubmit: SubmitHandler<FormValues> = async data => {
        console.log(data);
        setLeadState(true);
        Comagic.addOfflineRequest({
            'name': data.name,
            'phone': data.phone,
            'email': data.email,
            'message': 'Отправка данных'
        }, function (o: any) {
            let response = JSON.parse(o.response);
            if (response.success) {
                setLeadState(true);
                // GTM & Metrika
                try {
                    if (ga) {
                        ga("create", "UA-230982504-1", {name: "tracker"});
                        ga('tracker.send', 'event', 'submit', 'send_button');
                    }
                } catch (error) {
                    console.log(error);
                }

                try {
                    if (ym) {
                        ym(89127996, 'reachGoal', 'send_button');
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                alert('При отправке данных формы произошла ошибка!')
            }
        });
        reset();
    }
    const idCheckbox = useId() + '-checkbox';
    return (
        <div className={classNames(classes.root, {[classes.modalContent]: modal})}>
            <div className={classes.wrapper}>
                <div className={classes.header} dangerouslySetInnerHTML={{__html: !modal ? title : modal_title}}/>
                {description ? <div className={classes.subheader} dangerouslySetInnerHTML={{__html: !modal ? description : ''}}/> : false}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <input {...register("name", {
                            required: "Обязательное поле",
                            pattern: {
                                value: validName,
                                message: error.name
                            }
                        })} type="text" placeholder={input.name_placeholder}/>
                        {errors?.name && <p className="error">{errors.name.message}</p>}
                    </div>
                    <div>
                        <InputMask
                            mask={"+7-999-999-99-99"}
                            alwaysShowMask={false}
                            placeholder={input.number_placeholder}
                            {...register("phone", {required: error.required})}
                        />
                        {errors?.phone && <p className="error">{errors.phone.message}</p>}
                    </div>
                    <div>
                        <input {...register("email", {
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: error.email
                            }
                        })} data-input="email" type="email" placeholder={input.email_placeholder}/>
                        {errors?.email && <p className="error">{errors.email.message}</p>}
                    </div>

                    <div className={classes.frmBtnWrapper}>
                        <input id={idCheckbox} type="checkbox" checked={isCheckValid}
                               {...register("policyAgree", {required: true})}
                               onChange={() => {
                                   setIsCheckValid(prev => !prev)
                               }}

                        />
                        <label htmlFor={idCheckbox} className={classNames({[classes.checkboxWrapperDrilling]: errors?.policyAgree})}>
                            <span>Я согласен с обработкой <Link className={classes.policy} href={`${policy_link}`} target="_blank">персональных данных</Link>
                            </span>
                        </label>
                        <C_MainButton className={classNames(classes.formBtn, {[classes.formBtn]: modal})} text={modal ? btn[1].text : btn[0].text} form={true}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
