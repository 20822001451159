import { sizes } from '@/src/data/sizes';
import classes from './style.module.scss';
import classNames from "classnames";
import Image, { StaticImageData } from 'next/image';
import { Context } from '@/src/library';
import { useContext } from 'react';

interface InvestmentCard {
    name: string,
    description: string,
    image: {
        data?: {
            attributes?: {
                url: any,
            }
        }
    }
    metro: {
        station: string,
        way: string,
        info: string,
        color?: string
    }[],
    label_1: string,
    label_2?: string
}

export const C_InvestmentCard = ({className, item, count, activeSlide, setActiveSlide} : {className?: any, item: InvestmentCard, count?: number, activeSlide?: number, setActiveSlide?: Function}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {name, label_1, label_2, description, metro, image} = item;

    const {size}  = useContext(Context);
    const {width} = size;

    return (
        <div className={cls} onClick={(e) => width > sizes.widthNotebookPlus && setActiveSlide ? setActiveSlide(count): null}>
            <h3>
                {name}
                <span className={classNames(classes.underline,{[classes.pressedCardLine]: activeSlide === count })}></span>
            </h3>
            <span className={classNames(classes.label,{[classes.labelLong] : label_1 === 'Торговые помещения'})} dangerouslySetInnerHTML={{__html: label_1}}></span>
            <p className={label_2 ? classes.descriptionWidth : ''} dangerouslySetInnerHTML={{__html: description}}></p>
            {label_2
                ?
                    <span className={classes.label_2}>{label_2}</span>
                :
                    null
            }
            <Image width={507} height={336} src={image.data?.attributes?.url} alt="Фото башни"/>
            <div className={classes.properties}>
                {metro ?
                    <ul>
                        <li>
                            {width > sizes.widthNotebookPlus ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g transform="translate(7417 6012)">
                                <circle cx="10" cy="10" r="10" transform="translate(-7417 -6012)" fill={`${metro[0].color}`}/>
                                <circle cx="5" cy="5" r="5" transform="translate(-7412 -6007)" fill="#fff"/>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g transform="translate(7417 6012)">
                                    <circle cx="6" cy="6" r="6" transform="translate(-7417 -6012)" fill={`${metro[0].color}`}/>
                                    <circle cx="3" cy="3" r="3" transform="translate(-7414 -6009)" fill="#fff"/>
                                </g>
                            </svg>
                            }
                            {metro[0].station}
                        </li>
                        <li dangerouslySetInnerHTML={{__html: metro[0].way}}></li>
                        <li dangerouslySetInnerHTML={{__html: metro[0].info}}></li>
                        <li dangerouslySetInnerHTML={{__html: metro[1] ? metro[1]?.info : ''}}></li>
                    </ul>
                :
                    null
                }
            </div>
      </div>
  )
}