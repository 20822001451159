import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import {useContext} from "react";
import {Context} from '../../library';
import { sizes } from '../../data/sizes';
import { C_MainButton } from '../../components/c_MainButton';

interface ProfitData {
    header: string,
    project_name: string,
    type: string,
    image_desktop: {
        data?: {
            attributes?: {
                url?: any,
                height?: number,
                width?: number,
            }
        }
    },
    image_mobile: {
        data?: {
            attributes?: {
                url?: any,
                height?: number,
                width?: number,
            }
        }
    },
    news_title: string,
    news_description: string,
    metro: {
        station: string,
        way: string,
        info: string,
        color?: string
    }[],
    btn_text: string
}

export const S_ProfitTower = ({className, data} : {className?: any, data: ProfitData}) => {
    const cls = classNames(classes.root, {[className]: className});
    const {popup, size} = useContext(Context);
    const {width}= size;
    const [popupState, setPopupState] = popup;

    const {header, project_name, type, news_title, news_description, metro, btn_text, image_desktop, image_mobile} = data

    return (
        <div className={cls} id={'offices'}>
            <div className={classes.title} dangerouslySetInnerHTML={{__html: header}}></div>
            <div className={classes.name} dangerouslySetInnerHTML={{__html: project_name}}></div>
            <div className={classes.type} dangerouslySetInnerHTML={{__html: type}}></div>

            {width > sizes.widthNotebookPlus ? <div className={classes.render}>
                    <Image
                        src={image_desktop.data?.attributes?.url}
                        alt="Tower Ленинский"
                        width={image_desktop.data?.attributes?.width}
                        height={image_desktop.data?.attributes?.height}
                    />
                    </div>
                :
                <div className={classes.renderMobile}>
                    <Image
                        src={image_mobile.data?.attributes?.url}
                        alt="Tower Ленинский"
                        width={276}
                        height={230}

                        className={classes.render}
                    />
                </div>
            }
            <div className={classes.news} dangerouslySetInnerHTML={{__html: news_title}}></div>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: news_description}}></div>
            <ul className={classes.list}>
                {
                    metro.map((item, i) => {
                        return (
                            <li key={i} className={classes.item}>
                                <div className={classNames(classes.metro, {[classes.firstMetro]: i < 1},{[classes.secondMetro]: i > 0}, {[classes.backgroundStation]: item.station === null})} dangerouslySetInnerHTML={{__html: item.station}}></div>
                                <div className={classNames(classes.way, {[classes.backgroundWay]: item.way === null})} dangerouslySetInnerHTML={{__html: item.way}}></div>
                                <div className={classNames(classes.info, {[classes.square]: i < 1},{[classes.date]: i > 0})} dangerouslySetInnerHTML={{__html: item.info}}></div>
                            </li>
                        )
                    })
                }
            </ul>
            <C_MainButton className={classes.popupBtn} text={`${btn_text}`} onClick={() => setPopupState((o: any) => !o)}/>
        </div>
    );
}