import classes from './style.module.scss';
import classNames from "classnames";
import { useContext} from 'react';
import { Context } from '@/src/library';
import { sizes } from '@/src/data/sizes';
import { StaticImageData } from 'next/image';
import { C_InvestmentCard } from './c_InvestmentCard';
import { C_InvestmentSlider } from '@/src/sections/S_ProfitableInvestment/c_InvestmentSlider';
import { C_InvestmentList } from '@/src/sections/S_ProfitableInvestment/c_InvestmentList';

export interface InvestmentCard {
          name: string,
          description: string,
          image?: StaticImageData,
          metro: {
              station: string,
              way: string,
              info: string,
              color?: string,
          }[],
          label_1: string,
          label_2?: string,

}
interface OfferlineData {
  header: string,
  offerline_card: InvestmentCard[]
}

export const S_ProfitableInvestment = ({className, setActiveProject, data} : {className?: any, setActiveProject: Function, data: OfferlineData}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {size} = useContext(Context);
    const {width} = size;

    const {header, offerline_card} = data

    let allCards: any[] = [];

    offerline_card.map((tower: any, i: number) => {
        allCards.push(<C_InvestmentCard key={i} item={tower}/>)
    })

    return (
      <div className={classes.wrapRoot} id={'offices'}>
        <div className={cls}>
            <h2 dangerouslySetInnerHTML={{__html: header}}></h2>
            {width < sizes.widthNotebookPlus ?
                <C_InvestmentSlider setActiveProject={setActiveProject} items={allCards} />
            :
                <C_InvestmentList setActiveProject={setActiveProject} items={allCards}/>
            }
        </div>
      </div>
    )
}