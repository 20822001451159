import classes from './style.module.scss';
import classNames from "classnames";
import {C_TerritoryBlocks} from "./c_TerritoryBlocks";
import {C_Popup} from "./c_Popup";
import {useState, useContext} from "react";
import { Context } from '@/src/library';

export const S_Territory = ({className, data} : {className?: any, data: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);
    const [isPopup, setIsPopup] = useState(false);
    const {size}  = useContext(Context);
    const {width} = size;

    return (
        <div className={cls}>
            <C_TerritoryBlocks
                className={classes.C_TerritoryBlocks}
                data={data}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                setIsPopup={setIsPopup}
            />
            <C_Popup
                data={data.pagination}
                selectedSlide={selectedSlide}
                isPopup={isPopup}
                setIsPopup={setIsPopup}
            />
        </div>
    );
}