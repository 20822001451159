import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import SwiperCore, { EffectFade, Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import {useEffect} from "react";
// import type { Swiper } from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination, A11y, Autoplay]);

export const C_Slider = ({className, territoryData, setSelectedSlide, setSwiperInstance, setIsPopup} : {className?: any, territoryData: any, setSelectedSlide: any, setSwiperInstance: any, setIsPopup:(isPopup:boolean)=>void}) => {
    const cls = classNames(classes.root, {[className]: className });

    useEffect(() => {
        setSelectedSlide(1)
    },[setSelectedSlide])

    return (
        <div className={cls}>
            <Swiper
                className={classes.swiper}
                spaceBetween={5}
                onSwiper={setSwiperInstance}
                onSlideChange={
                    (data) => {
                        setSelectedSlide(data.activeIndex + 1);
                    }
                }
                navigation
            >

                {territoryData.map((el: any, i: number) => {
                    return (
                        <SwiperSlide
                            key={i}
                            className={classes.SwiperSlide}
                            onClick={() => setIsPopup(true)}
                        >
                            <Image
                                className={classes.Image}
                                src={el.image.data[0]?.attributes?.url}
                                alt={`plan ${i + 1}`}
                                width={922}
                                height={615}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}