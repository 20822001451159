import classes from './style.module.scss';
import classNames from "classnames";
import { useContext } from 'react';
import { Context } from '../../../library/index';

export interface Way {
    text: string,
    time: boolean,
    type: string,
    location: string,
    location_2?: string,
    transport_icon?: any
    orderMobile?: string,
}

export const C_WayBlock = ({className, data}:{className?: any, data: Way[]}) => {
    const cls = classNames(classes.root, {[className]: className });
    const {size}: any = useContext(Context)

    const {width} = size;
    return (
        <div className={cls}>
            <ul>
            {
                    data.map((item, i) => {
                        return (
                            <li key={i} className={data.length === i + 1 ? classes.street : ''}
                            style={width < 1280 ?
                                {
                                    order: item.orderMobile,
                                    margin: (item.orderMobile === '3') ? '0 36px 43px 0' : (item.orderMobile === '1')  ? '0 15px 18px 0' : '0 35px 18px 0',
                                }
                                :
                                {
                                    order: 'unset',
                                }
                            }
                            >
                                {data.length === i + 1 ?
                                    <div dangerouslySetInnerHTML={{__html: data[i].text}}></div> :
                                    <div className={item.time ? classes.time : classes.meter} dangerouslySetInnerHTML={{__html: `${item.text}`}}></div>
                                }
                                {
                                    item.location !== null ?
                                        <div className={classNames(classes.metro,
                                            {[classes.metro] : item.type === 'metro'},
                                            {[classes.metro_circle] : item.type === 'circle'},
                                            {[classes.metro_diameter] : item.type === 'diameter'},
                                            {[classes.airport] : item.type === 'airport'},
                                            {[classes.noneSVG] : item.type === 'none'},
                                            )} dangerouslySetInnerHTML={{__html: `${item.location}`}}></div>
                                    : null
                                }
                                {item.location_2 !== null ? <div className={classes.centre}>ИЦ «Сколково»</div> : null}
                            </li>
                        )
                    })
                }
            </ul>
         </div>
    )
}