import React, { useEffect, useState } from "react";

import classes from './stye.module.scss';
import classNames from "classnames";
import { StaticImageData } from 'next/image';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { S_Plans } from "../s_Plans";

// install Swiper modules

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
interface PlansData {
    header: string,
    plans_card: {
        name: string,
        floors: {
            floors: string,
            plan?: any
        }[],
        situationPlan: {
            mobile?: any,
            desktop?: any
        },
        implement: {text: string}[],
        square: {text: string}[],
        price: string,
        price_grow?: string,
        btn_text: string
    }[]
}

interface PlansSlider {
    className?: any,
    activeProject?: number,
    data: PlansData
}
export const S_PlansSlider = ({className, activeProject, data} : PlansSlider): JSX.Element => {
    const cls = classNames(classes.root, { [className]: className });
    const {header, plans_card} = data

    const [swiper, setSwiper]:any = React.useState<typeof Swiper | null | any>(null)
    const [isSitPlanShowed, setIsSitPlanShowed] = useState<boolean>(false);

    useEffect(() => {
        if (swiper && !swiper.destroyed) {
            swiper.slideTo(activeProject)
        }
    }, [activeProject, swiper])

    return (
        <div className={cls}>
            <Swiper
                pagination={{
                    el: '.swiper-pagination',
		            'clickable': true
                }}
                preventClicks={true}
                speed={0}
                slidesPerView={1}
                loopAdditionalSlides={3}
                spaceBetween={0}
                loop={true}
                className={classNames(classes.swiper)}
                initialSlide={0}
                allowTouchMove={false}
                onInit={(evt) => {
                    setSwiper(evt)
                }}
            >
            <h2 dangerouslySetInnerHTML={{__html: header}}></h2>
                {plans_card.map((item: any, i: any) => {
                    return (
                        <SwiperSlide className = {classNames(classes.swiperSlide,{[classes.swiperHeight]: (activeProject === 0 && !isSitPlanShowed) || (activeProject === 2 && !isSitPlanShowed)})} key={i}>
                            <S_Plans data={plans_card[i]} setIsSitPlanShowed={setIsSitPlanShowed}/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}