import React, {useContext} from 'react';
import Popup from 'reactjs-popup';
import classes from './style.module.scss'
import {S_Form} from "@/src/sections/s_Form";
import {Context} from '@/src/library';
import styled from 'styled-components';
import {C_SuccessLead} from "@/src/components/с_SuccessLead";

const StyledPopup = styled(Popup)`
      &-overlay {
        background-color: #43111D;
        @media (min-width: 600px) {
          background-color: #fff;
        }
      }`;
export const S_ModalForm = (data: any): JSX.Element => {
    const {popup, lead} = useContext(Context);
    const [popupState, setPopupState] = popup;
    const [leadState, setLeadState] = lead;
    const closeModal = () => setPopupState(false);

    return <StyledPopup open={popupState} onClose={closeModal} modal>
        <div className={classes.root}>
            <button className={classes.closeBtn} onClick={closeModal}></button>
            {
                !leadState ?
                <S_Form modal={true} data={data.data}/> :
                <C_SuccessLead header={`${data.data.success.title}`} subheader={`${data.data.success.description}`}
                               modal={true}/>
            }
            <div className={classes.modalLogo}></div>
        </div>
    </StyledPopup>;
}

