import classes from './style.module.scss';
import classNames from "classnames";
import {C_Tower_plans} from "./c_Tower_d_plans";
import {useState} from "react";

export interface Plan {
    name: string,
    implement: {text: string}[],
    square: {text: string}[],
    price: string,
    price_grow?: string,
    btn_text: string,
    floors: {
        floors: string,
        plan?: any
    }[],
    situationPlan: {
        mobile?: any,
        desktop?: any
    }
}

export interface Plans {
    className?: any,
    setIsPlanOpened?: any,
    data: Plan
}
export const S_Plans = ({className, setIsSitPlanShowed, data} : {className?: any, setIsSitPlanShowed: any, data: Plan}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);

    return (
        <div className={cls}>
            <C_Tower_plans
                data={data}
                setIsSitPlanShowed={setIsSitPlanShowed}
                className={classes.C_Tower_d_plans}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
            />
        </div>
    );
}