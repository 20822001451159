import classes from './style.module.scss';
import classNames from "classnames";
import {C_Slider} from "./c_Slider";
import {C_Pagination} from "./c_Pagination";
import {useContext, useState} from "react";
import type { Swiper } from 'swiper';
import { C_MainButton } from '@/src/components/c_MainButton';
import { Context } from '@/src/library';
import { C_WayBlock, Way } from '../С_WayBlock';

export interface territoryData {
    title: string,
    vertical_text: string,
    pagination: {
        title: string,
        content?: string,
        image: {
            data?: any
        }
    }[]
    btn: {
        text: string,
        link: string
    }[],
    way: Way[]
}

export const C_TerritoryBlocks = ({className, selectedSlide, setSelectedSlide, setIsPopup, data} : {className?: any, selectedSlide: number, setSelectedSlide: any, setIsPopup: any, data: territoryData}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [swiperInstance, setSwiperInstance] = useState<Swiper | null | any>(null);
    const {popup} = useContext(Context);
    const [popupState, setPopupState] = popup;

    const slideTo = (index: number) => {
        if (swiperInstance !== null) {
            swiperInstance.slideTo(index - 1, 0);
        }
    };
    const {title, pagination, btn, way} = data

    return (
        <div className={classNames(cls,{[classes.rootMap]: pagination[selectedSlide -1].content === null})}>
            <div className={classes.title}>{title}</div>
            <C_Pagination
                className={classes.C_Pagination}
                territoryData={pagination}
                selectedSlide={selectedSlide}
                slideTo={slideTo}
            />
            <C_Slider
                className={classes.C_Slider}
                territoryData={pagination}
                setSelectedSlide={setSelectedSlide}
                setSwiperInstance={setSwiperInstance}
                setIsPopup={setIsPopup}
            />
            {pagination[selectedSlide - 1].content !== null
            ?
                <div className={classes.contentText} dangerouslySetInnerHTML={{__html: `${pagination[selectedSlide - 1].content}`}}></div>
            :
                <div className={classes.way_wrapper}>
                    {pagination[selectedSlide - 1].content === null ? <C_WayBlock data={way}/> : null}
                </div>
            }
            <div className={classes.wrapButtons}>
                <C_MainButton text={`${btn[0].text}`} className={classes.C_MainBtn} mode={false} onClick={() => setPopupState((o: any) => !o)}/>
                <C_MainButton text={`${btn[1].text}`} link={`${btn[1].link}`} className={classes.C_RouteBtn} path={true}/>
            </div>
        </div>
    );
}