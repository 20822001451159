import classes from './style.module.scss';
import classNames from "classnames";

export const C_Pagination = ({className, data, selectedSlide, slideTo} : {className?: any, selectedSlide: number, data: any, slideTo: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const selectSlide = (arr: any, selectedEl: number, onClick: any) => {
        return (
            <div onClick={(e) => e.preventDefault()} className={classes.wrapPagination}>
                {arr.map((el: any, i: number) => {
                    let elementPosition = i + 1;
                    const selected = selectedEl === elementPosition ? 'regular__selected' : 'regular';
                    return <div key={elementPosition} className={classNames(classes.common, classes[`${selected}`])} onClick={() => {
                        onClick(elementPosition)
                    }}>{el.number}</div>
                })}
            </div>
        )
    }

    return (
        <div className={cls}>
            {selectSlide(data, selectedSlide, slideTo)}
        </div>
    );
}