import classes from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";

type MyType = {
    title: string;
    calendar: Month[];
}

interface Month {
    date: string,
    link: string
}
export const S_Diary = ({className, data}: {className?: any, data: MyType}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const [selected, setSelected] = useState(0)

    const {title, calendar} = data

    return (
        <div className={cls} id={'diary'}>
            <div className={classes.container}>
                    <h3 className={classes.title} dangerouslySetInnerHTML={{__html: title}}></h3>
                    <div className={classes.calendar}>
                        <button
                            className={classes.leftBtn}
                            onClick={() => {setSelected(prev => {
                            if (prev !== calendar.length - 1) {
                                return prev + 1;
                            }
                            return prev;
                        })}} />
                        <ul>
                            {calendar.map((el: Month, i: number) => {
                                return (
                                    <li
                                        key={el.date}
                                        className={classNames(classes.calendar_element, {[classes.active]: i === selected})}
                                        onClick={() => {setSelected(i)}}
                                    >
                                        {el.date}
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            className={classes.rightBtn}
                            onClick={() => {setSelected(prev => {
                            if (prev !== 0) {
                                return prev - 1;
                            }
                            return prev;
                        })}} />
                    </div>
                    <div className="content">
                        <div
                             className={classNames(classes.content_element, classes.active)}
                             style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                            <iframe
                                className={classes.iframe}
                                src={calendar[selected].link}
                                frameBorder="0"
                                allow="autoplay"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
        </div>
    );
}