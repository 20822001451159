import classes from './style.module.scss';
import classNames from "classnames";
import Image, { StaticImageData } from 'next/image';
import {Context} from '../../library';
import {useContext} from "react";
import { C_MainButton } from '@/src/components/c_MainButton';

interface HeroData {
    image?: any,
    description: {text: string}[],
    header: string,
    sale: string,
    vertical_text: string,
    award_link: string,
    btn_text: string
}

export const S_Hero = ({className, data}: {className?: any, data: HeroData}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const {popup} = useContext(Context);
    const [popupState, setPopupState]=popup;

    const  {header, sale, description, vertical_text, award_link, btn_text, image} = data;

    return (
        <div className={cls}>
            <div className={classes.wrapper}>
                <div className={classes.projectLogo} />
                <div className={classes.imageWrapper}>
                    <div className={classes.nextImage}>
                        <Image src={image.data.attributes.url} width={948} height={622} alt="Фото проекта"/>
                    </div>
                    <p>{sale}</p>
                    <p className={classes.vertical} dangerouslySetInnerHTML={{__html: vertical_text}}></p>
                </div>
                <div className={classes.description}>
                    <h3 dangerouslySetInnerHTML={{__html: header}}></h3>
                    <ul>
                        {description.map((item, i) =>
                            <li key={i}>{item.text}</li>
                        )}
                    </ul>
                </div>
                <div className={classes.award}>
                    <h3>ОФИСНЫЙ ДЕВЕЛОПЕР №1</h3>
                    <span>по рейтингу РБК</span>
                    <p>2023</p>
                    <a href={award_link} target={'_blank'}>Читать на РБК</a>
                </div>
                <div className={classes.btnWrapper}>
                    <C_MainButton className={classes.heroBtn} text={btn_text} onClick={() => setPopupState((o: any) => !o)}/>
                </div>
            </div>
            <div className={classes.imageWrapper_desktop}>
                <div className={classes.nextImage}>
                    <Image src={image.data.attributes.url} width={948} height={622} alt="Фото проекта"/>
                </div>
                <p>{sale}</p>
                <p className={classes.vertical} dangerouslySetInnerHTML={{__html: vertical_text}}></p>
            </div>
        </div>
    );
}
