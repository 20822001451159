import React, { useContext, useEffect} from "react";

import classes from './style.module.scss';
import classNames from "classnames";

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";
import { Context } from "@/src/library";
import { sizes } from "@/src/data/sizes";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const C_InvestmentSlider = ({className, items, setActiveProject} : {className?: any, items: any[], setActiveProject: Function}) => {
    const cls = classNames(classes.root, { [className]: className });

    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [swiper, setSwiper]:any = React.useState()
    const {size} = useContext(Context)
    const {width} = size

    useEffect(() => {
        setActiveProject(activeSlide)
    }, [activeSlide, setActiveProject])
    // ПРолистывает к слайду , если нажата строчка с заголовком

    const numSlider = (selected: number, nums: any[]): JSX.Element => {
        return (
            <div className={classes.numbersWrapper} >
                {nums.map((el, i) => {
                    const paginationClass = selected === i ? 'regular__selected' : 'regular';
                    return <div key={i} className={classes[`${paginationClass}`]} />
                })}
            </div>
        )
    }
    return (
        <div className={cls}>
            <Swiper
                pagination={{
                    el: '.swiper-pagination',
		            'clickable': true
                }}
                preventClicks={true}
                speed={700}
                slidesPerView={1}
                loopAdditionalSlides={3}
                spaceBetween={width < sizes.widthNotebookPlus ? -45 : 0}
                loop={true}
                className={classNames(classes.swiper)}
                initialSlide={0}
                onInit={(evt) => {
                    setSwiper(evt)
                }}
                onSlideChange={(swiper) => (setActiveSlide(swiper.realIndex))}
            >   
                {items.map((item, i) => {
                    return (
                        <SwiperSlide className = {classNames(classes.swiperSlide,{[classes.swiperSlideActive]: activeSlide === i})} key={i}>
                            {item}
                        </SwiperSlide>    
                    )
                })}
            </Swiper>
            {numSlider(activeSlide, items)}
        </div>
    )
}