import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import SwiperCore, { EffectFade, Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState} from "react";
import Popup from 'reactjs-popup';

// install Swiper modules

SwiperCore.use([EffectFade, Navigation, Pagination, A11y, Autoplay]);

export const C_Slider_plans = ({className, plans, setSelectedSlide, setSwiperInstance, data} : {className?: any, plans: any, setSelectedSlide: any, setSwiperInstance: any, data: any}) => {
    const cls = classNames(classes.root, {[className]: className });
    const ref:any = useRef(null);

    const [popupState, setPopupState] = useState<boolean>();
    const closeModal = () => setPopupState(false);

    useEffect(() => {
        setSelectedSlide(1)
    },[setSelectedSlide])

    const {name, floors} = data

    return (
        <div className={cls}>
            <Swiper
                className={classes.swiper}
                spaceBetween={name === 'Башня S' ? 5 : 5}
                onSwiper={setSwiperInstance}
                onSlideChange={
                    (data) => {
                        setSelectedSlide(data.activeIndex + 1);
                    }
                }
                navigation
                style={name === "Башня L" ? {maxWidth: 871 + 'px'} : name === "Башня S" ? {maxWidth: 562 + 'px'} : {maxWidth: 945 + 'px'}}
            >
                {floors.map((el: any, i: number) => {
                    return (
                        <SwiperSlide
                            key={i}
                            className={classNames(classes.SwiperSlide,{[classes.swiperSlideS]: name === 'Башня S' || name === 'Ритейл' && i === 3})}
                            style={name === "Башня L" ? {maxWidth: 871 + 'px'} : name === "Башня S" ? {maxWidth: 562 + 'px', marginLeft: 0 + 'px'} : {maxWidth: 945 + 'px'}}
                        >
                            <Popup open={popupState} ref={ref} trigger={
                                <Image
                                className={classNames(classes.Image, {[classes.ImageTowerS]: name === 'Башня S' || name === 'Ритейл' && i === 3})}
                                src={el.plan.data.attributes.url}
                                alt={`plan ${i + 1}`}
                                width={945}
                                height={462}
                                style={name === "Башня L" ? {maxWidth: 871 + 'px'} : name === "Башня S" ? {maxWidth: 562 + 'px'} : {maxWidth: 945 + 'px'}}
                                onClick={() => setPopupState((o: any) => !o)}
                            />
                            } onClose={closeModal}
                            lockScroll={true}
                            key={`tp-${i + 1}`} modal>
                                        <div
                                            className={cls}
                                        >
                                            <div className={classNames(classes.wrapImg,{[classes.wrapImageRotate]: el.plan.width > 1800})}>
                                                <button
                                                    className={classes.closeBtn}
                                                    onClick={() => ref.current ? ref.current.close(): null}
                                                />
                                                <Image
                                                    className={classNames(classes.imagePopup, {[classes.imageRotate]: el.plan.width > 1800},{[classes.popupSquareTower]: name === 'Башня S' || name === 'Ритейл' && i === 3})}
                                                    src={el.plan.data.attributes.url}
                                                    alt={`plan ${i + 1}`}
                                                    width={name === 'Ритейл' ? 1850 : 945}
                                                    height={name === 'Ритейл' ? 580 : 462}
                                                    style={name === "Башня L" ? {maxWidth: 871 + 'px'} : {maxWidth: 945 + 'px'}}
                                                />
                                            </div>
                                        </div>
                            </Popup>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}
