import classes from './style.module.scss';
import classNames from "classnames";
import { C_MainButton } from '@/src/components/c_MainButton';
import {useContext} from "react";
import {Context} from "@/src/library";

interface InvestmentData {
  title: string,
  subtitle: string,
  description: string,
  vertical_text: string,
  btn_text: string
}

export const S_Investment = ({className, data} : {className?: any, data: InvestmentData}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const {menu, popup} = useContext(Context);
    const [popupState, setPopupState] = popup;

    const {title, subtitle, description, vertical_text, btn_text} = data;

    return (
      <div className={classes.wrapRoot}>
        <div className={cls}>
            <div className={classes.block_wrapper}>
            <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
                <div className={classes.wrapper_description} dangerouslySetInnerHTML={{__html: description}}></div>
                <C_MainButton className={classes.btn_more}  text={`${btn_text}`} onClick={() => setPopupState((o: any) => !o)} />
                <div className={classes.vert_text} dangerouslySetInnerHTML={{__html: vertical_text}}></div>
                <div className={classes.procent}><span>0%</span> Рассрочка</div>
            </div>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: subtitle}}></div>
        </div>
      </div>
  )
}
