import React, { useContext, useEffect} from "react";
import classes from './style.module.scss';
import classNames from "classnames";
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";
import { Context } from "@/src/library";
import { sizes } from "@/src/data/sizes";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const C_Slider = ({className, items, isIndexActiveSlide, setChange,setIsIndexActiveSlide, isSwipe, setIsSwipe} : {className?: any, items: any[], isIndexActiveSlide: number, setChange: any,setIsIndexActiveSlide: any, isSwipe: boolean, setIsSwipe: any}): JSX.Element => {
    const cls = classNames(classes.root, { [className]: className });

    const [slide, setSlide] = useState<number>(0);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [swiper, setSwiper]:any = React.useState()
    const {size} = useContext(Context)
    const {width} = size

    useEffect(() => {
        if (swiper && isSwipe && !swiper.destroyed) {
            swiper.slideTo(isIndexActiveSlide)
            setIsSwipe(false);
        }
    }, [isSwipe, setIsSwipe, isIndexActiveSlide, swiper])

    return (
        <div className={cls}>
            {<div className={classes.swiper_button_prev} onClick={() => (swiper.slidePrev(), slide > 0 ? setSlide(slide - 1) : setSlide(slide))}
            />}
            <Swiper
                navigation={{
                    prevEl: '.swiper_button_prev',
                    nextEl: '.swiper_button_next',
                }}
                onSlideChangeTransitionEnd={(swiper) => (setChange(true),setActiveSlide(swiper.realIndex),setIsEnd(true))}
                speed={width <= 1280 ? 700 : 0}
                slidesPerView={1}
                loopedSlides={1}
                spaceBetween={width < sizes.widthNotebookPlus ? -58 : 0}
                loop={true}
                allowTouchMove={width > 1280 ? false : true}
                className={classNames(classes.swiper)}
                initialSlide={0}
                onInit={(evt) => {
                    setSwiper(evt)
                }}
                onSlideChange={ (swiper) => (setIsIndexActiveSlide(swiper.realIndex + 1), setIsEnd(false))}
            >
                {items.map((item, i) => {
                    return (
                        <SwiperSlide className = {classNames(classes.swiperSlide,{[classes.swiperSlideActive]: activeSlide === i && isEnd})} key={i}>
                            {item}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {<div className={classes.swiper_button_next} onClick={() => (swiper.slideNext(), slide < items.length -1 ? setSlide(slide + 1) : setSlide(slide))}
            />}
        </div>
    )
}