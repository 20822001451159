import React from "react";
import classes from './style.module.scss';
import classNames from "classnames";

export const C_Floors = ({className, selectedSlide, plans} : {className?: any, selectedSlide: number, plans: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });
    const mark = ', ';

    const showFloors = (arr: any, selectedEl: number) => {
        return (
            <div >
                {arr.map((el: any, i: number) => {
                    let elementPosition = i + 1;
                    const selected = selectedEl === elementPosition ? 'regular__selected' : 'regular';
                    return (
                        <div
                            key={el+i}
                            className={classes[`${selected}`]}
                        >
                            <React.Fragment key={el}>
                                <span
                                    className={classes.sign}
                                >
                                    {el.floors}
                                </span>
                                <span className={classes.sign}>{`${mark}`}</span>
                            </React.Fragment>
                        </div>
                    )
                })}
            </div>
        )
    };

    return (
        <div className={cls}>
            <div className={classes.avalible}>Данная планировка доступна на{'\u00A0'}этажах</div>
            {showFloors(plans, selectedSlide)}
        </div>
    );
}