import Head from 'next/head'
import {S_HealthyBuilding} from '@/src/sections/s_HealthyBuilding';
import {S_StoneStandarts} from '@/src/sections/s_StoneStandarts';
import {S_ProfitableInvestment} from '@/src/sections/S_ProfitableInvestment';
import {S_Hero} from '@/src/sections/s_Hero_section';
import {S_Form} from "@/src/sections/s_Form";
import {S_ModalForm} from "@/src/sections/s_ModalLead";
import {C_SuccessLead} from "@/src/components/с_SuccessLead";
import { S_Investment } from '@/src/sections/s_Investment';
import { S_PlansSlider } from '@/src/sections/S_PlansSlider';
import React, {useContext, useState } from 'react';
import { S_Diary } from '@/src/sections/s_Diary';
import { S_Territory } from '@/src/sections/s_Territory';
import {Context} from '@/src/library';
import { S_Advantages } from '@/src/sections/s_Advantages';
import {S_ProfitTower} from '@/src/sections/s_ProfitTower/index';
import { Low } from 'lowdb'
import { JSONFile } from 'lowdb/node'

type Data = {
  common: {},
  leninskiy: {}
}

export default function Home({pageData}: any):JSX.Element {
    const [ activeProject, setActiveProject] = useState<number>(0);
    const {lead} = useContext(Context);
    const [leadState, setLeadState] = lead;

    const {hero_block, profit_block, offerline_block, plans_block, location_block, diary_block, advantages_block, form_block, investment_block, standarts_block, healthy_block} = pageData.landing.data.attributes

    return (
    <>
      <Head>
        <title>Stone Ленинский</title>
        <meta property="og:title" content="Продажа или аренда премиальной офисной недвижимости у метро Ленинский проспект от девелопера STONE HEDGE." key="title" />
        <meta property="og:image" content={hero_block.image.data?.attributes?.url}></meta>
        <meta property="og:description" content="Бизнес-центр представляет собой две современные офисные башни — клубная 12-этажная башня S и 23-этажная башня L" key="description"/>
        <meta property="og:site_name" content="STONE Ленинский" key="site_name"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
          <S_Hero data={hero_block}/>
          <S_ProfitTower data={profit_block}/>
          <S_ProfitableInvestment setActiveProject={setActiveProject} data={offerline_block}/>
          <S_PlansSlider activeProject={activeProject} data={plans_block}/>
          <S_Territory data={location_block}/>
          <S_Diary data={diary_block}/>
          <S_Advantages data={advantages_block}/>
          {
              !leadState ?
                  <S_Form data={form_block}/> :
                  <C_SuccessLead header={`${form_block.success.title}`} subheader={`${form_block.success.description}`}
                                 modal={true}/>
          }
          <S_Investment data={investment_block}/>
          <S_StoneStandarts data={standarts_block}/>
          <S_HealthyBuilding data={healthy_block}/>
          <S_ModalForm data={form_block}/>
      </main>
    </>
  )
}

export async function getServerSideProps({}) {

  const file = ('./db.json')
  const adapter = new JSONFile<Data>(file)
  const defaultData: any = {}
  const db = new Low<Data>(adapter, defaultData)
  await db.read()

  const common = db.data.common
  const landing = db.data.leninskiy

  let pageData = {landing, common}

  if (pageData === null) {
    return {
      redirect: {
        permanent: false,
        destination: '/500'
      }
    }
  }

  return {
      props: {pageData}
  };
}