import classes from './style.module.scss';
import classNames from "classnames";
import Image from 'next/image';
import useWindowSize from "../../../../hooks/useWindowSize";
import {useRef, useState} from "react";
import Popup from 'reactjs-popup';

export const C_Situation_Plan = ({className, image, setIsSitPlanShowed} : {className?: any, image: string | any | any[] | string[], setIsSitPlanShowed: any}) : JSX.Element => {
    const [showPlan, setShowPlan] = useState(false);
    const cls = classNames(classes.root, {[className]: className, [classes.rootShownMobile]: showPlan });
    const size = useWindowSize();
    const {width, height}: any = size;
    const [popupState, setPopupState]= useState<boolean>();
    const closeModal = () => setPopupState(false);

    const ref:any = useRef(null);

    return (
        <div className={cls}>
            <button
                className={classNames(classes.showPlanBtn, {[classes.showPlanBtn_opened]: showPlan})}
                onClick={() => (setShowPlan(prev => !prev), setIsSitPlanShowed((prev:any) => !prev))}
            >
                Ситуационный план
            </button>
            <div
                className={classNames(classes.wrapPlan, {[classes.showWrapPlan]: showPlan})}
            >
                { width !== undefined && width >= 1281 ? (
                        <Popup open={popupState} ref={ref} trigger={
                            <Image
                            className={classes.plan}
                            src={image.desktop?.data?.attributes?.url}
                            alt={'plan'}
                            width={486}
                            height={351}
                        />
                        } onClose={closeModal}
                        lockScroll={true}
                        key={`tp-${image}`} modal>
                                    <div className={classes.wrapImg}>
                                            <button
                                                className={classes.closeBtn}
                                                onClick={() => ref.current ? ref.current.close(): null}
                                            />
                                            <Image
                                                className={classes.planPopup}
                                                src={image.desktop?.data?.attributes?.url}
                                                alt={'plan'}
                                                width={486}
                                                height={351}
                                            />

                                    </div>
                        </Popup>
                    ) : (

                        <Popup open={popupState} ref={ref} trigger={
                            <Image
                                className={classes.plan}
                                src={image.mobile?.data?.attributes?.url}
                                alt={'plan'}
                                width={486}
                                height={351}
                            />
                        } onClose={closeModal}
                        lockScroll={true}
                        key={`tp-${image}`} modal>
                                    <div className={classes.wrapImg}>
                                        <button
                                            className={classes.closeBtn}
                                            onClick={() => ref.current ? ref.current.close(): null}
                                        />
                                        <Image
                                            className={classes.planPopup}
                                            src={image.mobile?.data?.attributes?.url}
                                            alt={'plan'}
                                            width={486}
                                            height={351}
                                        />
                                    </div>
                        </Popup>
                    )
                }

            </div>
        </div>
    );
}

