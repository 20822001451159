import classes from './style.module.scss';
import classNames from "classnames";

export const C_Pagination = ({className, plans, selectedSlide, slideTo} : {className?: any, selectedSlide: number, plans: any, slideTo: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const selectSlide = (arr: any, selectedEl: number, onClick: any) => {
        return (
            <div className={classes.wrapPagination}>
                {arr.map((_: any, i: number) => {
                    let elementPosition = i + 1;
                    const selected = selectedEl === elementPosition ? 'regular__selected' : 'regular';
                    return <div key={elementPosition} className={classNames(classes.nums, classes[`${selected}`])} onClick={() => {
                        onClick(elementPosition)
                    }}>{elementPosition}</div>
                })}
            </div>
        )
    }

    return (
        <div className={cls}>
            <div className={classes.variants}>
                <span className={classes.desktopView}>Варианты планировок</span>
                <span className={classes.mobileView}>Выберите вариант планировки</span>
            </div>
            {selectSlide(plans.floors, selectedSlide, slideTo)}
        </div>
    );
}