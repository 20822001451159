import classes from './style.module.scss';
import classNames from "classnames";
import {C_Blocks} from "./c_Blocks";

import {useState} from "react";

interface AdvantagesData {
    header: string,
    advantage_card: {
        title: string,
        content: string,
        number: string,
        image?: any
    }[]
}

export const S_Advantages = ({className, data} : {className?: any, data: AdvantagesData}) => {
    const cls = classNames(classes.root, {[className]: className });
    const [selectedSlide, setSelectedSlide] = useState(1);

    return (
        <div className={cls}>
            <C_Blocks
                className={classes.C_Blocks}
                data={data}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
            />
        </div>
    );
}